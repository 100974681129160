import React from 'react'
import Authenticities from '../images/projects/pay-project.png'

import { Link } from "gatsby"


const HomeRecentProjects = () => {

    return (
        
        <section style={{ paddingTop: '0px' }} id="featured" data-scrollax-parent="true">
        <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span> CLIENTS </div>
        <div className="container">
            <div className="section-title fl-wrap">
                <h3>FEATURED</h3>
                <h2> FEATURED<span> CLIENTS  </span></h2>
            </div>
            <div class="custom-inner-holder  mar-bottom">
            
                               
            <div class="custom-inner">
                <div class="row">
                    <div class="col-md-6">
                        <div class="custom-inner-header ">
                        
                            <h3> Authenticities</h3>
                              <span>  Authenticities Online Payment and Managment Support </span> 
                        </div>
                        <div class="ci-num"><span>01. -</span></div>
                    </div>
                    <div class="col-md-6"><img src={Authenticities} class="respimg" data-scrollax="properties: { translateY: '-170px' }" alt="" /></div>
                  
                </div>
            </div>
          
        
           
        </div>
            {/* <div className="fl-wrap mar-top">
                <div className="srv-link-text">
                    <h4>Need more info ? Visit my services page :  </h4>
                    <a href="services.html" className="btn float-btn flat-btn color-btn">My Services</a>
                </div>
            </div> */}
        </div>
        <div className="bg-parallax-module" data-position-top="90"  data-position-left="30" data-scrollax="properties: { translateY: '-150px' }"></div>
        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
        <div className="sec-lines">
            <div className="container full-height"><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div></div>
        </div>
    </section>
                 
    )
}

export default HomeRecentProjects
