import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="highlights">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Our Highlights</h3>
                    <h2>PAY <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-analysis-icon"></i>
                            </div>
                            <h4>100% Customizable</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Tailored to your exact needs</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-requirements-articulation-icon"></i>
                            </div>
                            <h4>100% Web Browser Based</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>No client end software is required.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-design-icon"></i>
                            </div>
                            <h4>100% Responsive</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Run it on any device (phone, tablet, laptop, desktop, HDTV,etc.)</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-support-icon"></i>
                            </div>
                            <h4>100% Open Source</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Host it on any deployment configuration (on-premise, cloud, etc.)</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-analysis-1-icon"></i>
                            </div>
                            <h4>Live Dashboard</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>For real-time tracking and corrective action.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">05.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-support-1-icon"></i>
                            </div>
                            <h4>Secure</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>Multilayered decoupled abstracted security architecture including SSL encryption.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">06.</span>
                        </li>
                       
                        
                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
