import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ScreenShot1 from '../images/overview/pay-1.png'
import ScreenShot2 from '../images/overview/pay-2.png'
import ScreenShot3 from '../images/overview/pay-3.png'


const slides = [
    {
        id: 1,
        image: ScreenShot1,
   
    },
    {
        id: 2,
        image: ScreenShot2,
  
    },
    {
        id: 3,
        image: ScreenShot3,
   
    }
]

const HomeAbout = () => {

    return (
        <div>

        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Overview<span>//</span></div>
                <div className="container">
                <div className="row">
                    
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2><span>Expediting</span> Business Outcomes</h2>
                            <p>Pay by PragICTS provides the platform to enable your customers the convenience to pay online with ease from any Internet-enabled device using their credit / debit cards.</p>
                            <p>Pay enables you to raise invoices online and track them to completion through its management dashboard.</p>
                            <p>Pay can be tailored to work with any payment gateway and provides a decoupled and abstracted architecture with SSL enablement for security and confidence.</p>

                           
                           
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                    <div  id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap" >
                <div  className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg" style={{ display: 'flex',alignItems:'center',justifyContent:'center' }}><img src={slide.image} style={{ objectFit: 'contain',width:'800px' }}/></div>
                      
                        <div className="half-hero-wrap">
                          
                          
                           
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
           
               </div>
                    </div>
                   
                    
                </div>
                
            </div>

           

            <div className="sec-lines"></div>
        </section>
        

        </div>
        
    )
}

export default HomeAbout
