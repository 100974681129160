import React from 'react'
import mastercard from '../images/projects/master.jpg'
import cybersource from '../images/projects/cybersource.jpeg'
import payhere from '../images/projects/payhere.jpeg'
import paypal from '../images/projects/paypal.jpeg'
import visa from '../images/projects/visa.png'




const payment = [
    {
        id: 1,
        company: 'Mastercard payment gateway',
        thumbnail: mastercard,
        tags: [
            {
                id: 1,
                title: ''
            }
        ]
    },
    {
        id: 2,
        company: 'Cybersource',
        thumbnail: cybersource,
        tags: [
            {
                id: 2,
                title: ''
            }
        ]
    },
    {
        id: 3,
        company: 'Payhere',
        thumbnail: payhere,
        tags: [
            {
                id: 3,
                title: ''
            }
        ]
    },
    {
        id: 4,
        company: 'Paypal',
        thumbnail: paypal,
        tags: [
            {
                id: 4,
                title: ''
            }
        ]
    },
    {
        id: 5,
        company: 'Visa',
        thumbnail: visa,
        tags: [
            {
                id: 5,
                title: ''
            }
        ]
    }
   
]

const HomePayment = () => {

    return (
        <section className="dark-bg" id="payment_provides">
            <div className="fet_pr-carousel-title">
                <div className="fet_pr-carousel-title-item">
                    <h3>Integrated Payment Gateways</h3>
               
                </div>
            </div>
            <div className="slider-carousel-wrap fl-wrap">
                <div className="fet_pr-carousel cur_carousel-slider-container fl-wrap">
                    {payment.map(project => (
                        <div key={project.id} className="slick-item">
                            <div className="fet_pr-carousel-box">
                                <div className="fet_pr-carousel-box-media fl-wrap">
                                    <img src={project.thumbnail} className="respimg" alt={project.company} title={project.company} />
                                    <a href={project.thumbnail} className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search"></i></a>
                                </div>
                                <div className="fet_pr-carousel-box-text fl-wrap">
                                    <h3><a href="#">{project.company}</a></h3>
                                    <div className="fet_pr-carousel-cat">
                                        {
                                            project.tags.map(tag => (
                                                <a key={tag.id} href="javascript:;">{tag.title}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="sp-cont sp-arr sp-cont-prev"><i className="fal fa-long-arrow-left"></i></div>
                <div className="sp-cont sp-arr sp-cont-next"><i className="fal fa-long-arrow-right"></i></div>
            </div>
            <div className="fet_pr-carousel-counter"></div>
        </section>
    )
}

export default HomePayment
